.about-section {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
}

.about-section p {
    font-size: 1.2em; /* Slightly larger font size */
    line-height: 1.6; /* Space out lines of text for readability */
    color: #333; /* Dark gray color for the text */
    text-align: justify; /* Align the text to both left and right margins */
    text-indent: 50px; /* Indent the first line of each paragraph */
  }
  