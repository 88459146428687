.container {
    margin: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
  }

.crop {
    width: 500px;
    height: 500px;
    overflow: hidden;
}

.crop img {
  flex: 0 0 calc(33.33%);
  margin: 5px;
  object-fit: cover;
  height: 100%; 
  width: 100%;
}
  