nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.menu-item {
  float: left;
  margin-left: 100px;
}

.menu-item a {
  display: block;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 30px 30px;
  text-decoration: none;
}

.menu-item a:hover {
  color: #676767;
}

button {
  height: 40px;
  background-color: #b0b0b0;
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 150px;
  border-radius: 15px;
  cursor: pointer;
  transition-duration: 0.4s;
}

button:hover {
  background-color: #7a7a7a;
}
