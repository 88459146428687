.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.row.reverse {
    flex-direction: row-reverse;
}

.picture {
    width: 50%;
    opacity: 95%;
}

.home-text {
    width: 50%;
    font-family: Arial, sans-serif;
    /* Use a modern sans-serif font */
    line-height: 1.6;
    /* Space out lines of text for readability */
    color: #333;
    /* Dark gray color for the text */
    padding-left: 10%;
    padding-right: 10%;
    /* Add some padding around the content */
    margin-bottom: 20px; /* Add some space below each paragraph */
    text-indent: 5%;
}