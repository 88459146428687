body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    margin-top: auto;
    background-color: #333;
    color: #fff;
    padding: 20px 0;
}

.footer-container {
    width: 80%;
    margin: 0 auto;
}

.footer-container p {
    color: white;
    margin: 0;
}

.footer-text {
    line-height: 1.6;
}