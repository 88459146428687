.company-name {
    font-size: 1.6em; /* Make the text larger */
    color: #333; /* Dark gray color */
    font-weight: bold; /* Make the text bold */
    text-transform: uppercase; /* Convert text to uppercase */
    letter-spacing: 2px; /* Increase spacing between letters */
    margin: 2%; /* Add some margin at the bottom */
    text-decoration: none;
    text-align: center;
  }
  
.App-header {
    background-color: #747474;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6em;
    color: white;
    padding: 0;
  }